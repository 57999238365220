html,
body,
#root {
	height: 100%;
}

.custom-modalpoxes-form-page {
	background-color: white;
	border-radius: 10px;
	z-index: 1;
	width: 100%;
	height: 100%;
	max-width: 100%;
	max-height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	
	@media (min-width: 758px) {
		max-width: 560px;
		max-height: 640px;
		overflow-y: auto;
	}

	form {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		overflow-y: auto;

		.fields {
			flex-grow: 1;
			padding: 16px;
			overflow-y: auto;
		}

		.footer {
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: 100;
			position: sticky;
			padding: 16px;
			background-color: rgb(224, 224, 224);
		}
	}
}