$primary-color: #042954;

.main-layout {
  display: flex;

  main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    header {
      height: 80px;
      background-color: $primary-color;
      padding: 0 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      img.logo {
        max-width: 145px;
      }

      > .header-actions {
        width: 280px;
        display: flex;
        gap: 20px;

        .currency-filter {
          width: 200px;
          display: flex;
          justify-content: center;
          align-items: center;

          .select-dropdown {
            z-index: 1000;
            width: 100%;
          }
        }

        .notification {
          width: 60px;
          display: flex;
          justify-content: center;
          align-items: center;

          > .btn {
            font-size: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        > .add-pox a.btn {
          display: flex;
          height: 42px;
          width: 100px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          font-size: 0.8rem;
          font-weight: 700;
          line-height: 0;
          margin: 0;
          border: none;
          background: linear-gradient(-150deg, #ea4335, #ea433580) !important;
        }
      }

      @media (max-width: 480px) {
        height: 58px;
        gap: 8px;
        padding: 0 10px;

        img.logo {
          max-width: 96px;
        }

        > .header-actions {
          width: 70px;

          .notification {
            width: 40px;
          }

          .currency-filter {
            position: fixed;
            top: calc(58px + 45px + 4px);
            right: calc(100px + 5px + 4px);
            z-index: 401;
            background-color: #042954;
            width: max-content;
            height: 32px;
            border-radius: 8px;
            a {
              font-size: 0.7rem;
            }
          }

          > .add-pox {
            position: fixed;
            top: calc(58px + 45px + 4px);
            right: 5px;
            z-index: 401;

            a.btn {
              height: 32px;
              width: 100px;
              border-radius: 8px;
              gap: 6px;
              font-size: 0.7rem;
            }
          }
        }
      }
    }

    > section.flat-map {
      flex-grow: 1;

      .map-view {
        .leaflet-control-geosearch.leaflet-geosearch-bar {
          position: fixed;
          top: 0;
          right: calc(72px + 24px + 260px + 40px);
          height: 80px;
          margin: 0;
          display: flex;
          align-items: center;
          width: 360px;
          border: none;
          outline: none;
          // background-color: $primary-color;

          > form {
            width: 100%;
            height: 42px;
            padding: 0;
            margin: 0;
            box-shadow: none;
            background-color: $primary-color;

            > input {
              height: 100% !important;
              width: 50% !important;
              outline: none;
              border-radius: 4px;
              margin: 0;
              font-size: 12px;
              height: 26px;
              text-indent: 8px;
              font-weight: 500;
            }

            > a.reset {
              top: 50%;
              right: 4px;
              transform: translateY(-50%);
              border-radius: 0;
              padding: 0 4px;
              font-weight: 700;
              font-size: 1rem;
              max-height: 100%;
            }

            > .results {
              position: absolute;
              top: 100%;
              left: 00%;
              width: 100%;
            }
          }
        }
      }

      @media (max-width: 480px) {
        .map-view {
          .leaflet-control-geosearch.leaflet-geosearch-bar {
            height: 58px;
            max-width: unset;
            width: calc(100% - 200px);
            left: calc(115px);
            right: unset;

            > form {
              height: 34px;

              > input {
                font-size: 11px;
              }
            }
          }
        }
      }
    }
  }
}
